export interface Logo {
  key: string;
  locationID: string;
  lat: number;
  long: number;
  active: boolean;
}

export enum State {
  AB = 'Alberta',
  BC = 'British Columbia',
}

export interface MarqueeLogo {
  key: string;
  state: State;
  active: boolean;
}
