import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeroBanner from '../../components/sections/hero-banner';
import Feature from '../../components/sections/industry/feature-block';
import WhyPatronscan from '../../components/sections/why-patronscan';
import ConnectWithUs from '../../components/sections/connect-with-us';
import TripleFeature from '../../components/sections/triple-feature';
import { getDemoLink } from '../../services/demo-selector';
import LogoBanner, { IndustryType } from '../../components/sections/logo-banner';
import SEO from '../../components/seo';
import { Route } from '../../models/site-links';
import './retail-vape.css';

const RetailVapeIndustry = () => {
  const data = useStaticQuery(graphql`
    query {
      countertop: file(relativePath: { eq: "product-card/countertop-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodeScanning: file(relativePath: { eq: "feature-images/barcode_no_bueno.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flaggedReseller: file(relativePath: { eq: "feature-images/flaggedreseller.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyImage: file(relativePath: { eq: "vape/privacy.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "hero-images/fake-ID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      trusted: file(relativePath: { eq: "icons/fraudprotect.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      challengeQuestions: file(relativePath: { eq: "icons/questions.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      expiredID: file(relativePath: { eq: "icons/expiredIDs.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vapeReseller: file(relativePath: { eq: "vape/vape-reseller.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      onlineIDScan: file(relativePath: { eq: "vape/onlineIDScan.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      onlineVerify: file(relativePath: { eq: "vape/onlineVerify.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeDetect: file(relativePath: { eq: "feature-images/scanners/rf-fakeID.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insideScanner: file(relativePath: { eq: "feature-images/scanners/scanner-inside.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      passportScanner: file(relativePath: { eq: "feature-images/scanners/pass-verified.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstvapelogo: file(relativePath: { eq: "logos/vape/QCV.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondvapelogo: file(relativePath: { eq: "logos/vape/daawgs-vape.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdvapelogo: file(relativePath: { eq: "logos/vape/e-vape.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fourthvapelogo: file(relativePath: { eq: "logos/vape/altvape.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO
        title="ID Scanners for Vape | Prevent Youth Uptake | Patronscan"
        description="Keep vaping products out of the hands of youth. Use Patronscan's advanced ID scanners to spot fake IDs and protect legal vape. See how we help."
      />
      <HeroBanner
        heroImage={data.desktopImage.childImageSharp.fluid}
        heading="Trusted ID Scanners"
        subHeading="Catch fake IDs, spot minors and prevent youth uptake."
        videoLink="https://player.vimeo.com/video/460368325?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="VapeHero:BookDemo"
      />
      <LogoBanner industry={IndustryType.RetailVape} />
      <Feature
        image={data.fakeDetect.childImageSharp.fluid}
        heading="Spot minors and"
        headingThin="catch fake IDs"
        subHeading="Fake IDs are cheaper and better than ever before. Use Patronscan, the most accurate fake ID scanner."
        description="Patronscan catches thousands of fake IDs every week. Our fake ID detection algorithm can authenticate IDs and identify fakes within seconds."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="VapeFeature:SpotMinors"
      />
      <Feature
        rightAlign
        image={data.insideScanner.childImageSharp.fluid}
        heading="Industry leading"
        headingThin="fake ID detection"
        subHeading="Patronscan scanners use dynamic lighting and a powerful ID scanning network to identify fakes."
        description="Strengthen detection by comparing scans against known fake ID templates that are sourced using our proprietary worldwide ID scanning network."
        linkText="Learn More"
        link={Route.TalkToAnExpert}
        buttonId="VapeFeature:IndustryLeading"
      />
      <Feature
        image={data.passportScanner.childImageSharp.fluid}
        heading="Validate age and expiry"
        headingThin="from over 4,500 IDs"
        subHeading="Minors often use out-of-state fake IDs that are more difficult for employees to catch."
        description="Patronscan scans IDs from anywhere in North America including military IDs, status cards and passports. It also support verifying IDs issued from most major issuing authorities around the world."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="VapeFeature:ValidateAgeExpiry"
      />
      <Feature
        rightAlign
        image={data.onlineVerify.childImageSharp.fluid}
        heading="Age gate your website"
        headingThin="with simple integrations"
        subHeading="Check IDs and verify age before providing access to vape products sold on your online store."
        description="Customers upload photos of their ID to prove their age with Patronscan. Comply with industry regulations and grow your e-commerce presence using aggregated demographic reporting."
        linkText="Learn More"
        link={Route.Developer}
        buttonId="VapeFeature:AgeGateWebsite"
      />
      <Feature
        image={data.vapeReseller.childImageSharp.fluid}
        heading="Catch resellers with"
        headingThin="the shared flagged list"
        subHeading="Spot bootleggers flagged by other vape shops to prevent youth from purchasing on the black market."
        description="Disrupt the black market by identifying resellers when they scan their ID. Receive alerts when individuals frequent multiple vape shops in a short period of time."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="VapeFeature:CatchResellers"
      />
      <Feature
        rightAlign
        image={data.privacyImage.childImageSharp.fluid}
        heading="Secure and"
        headingThin="privacy compliant"
        subHeading="Patronscan works hard to remain compliant in all jurisdictions in which it operates."
        description="Most ID scanners do not comply with privacy legislation in Canada and US jurisdictions. Rest assured that Patronscan routinely monitors privacy laws to stay compliant."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="VapeFeature:SecurePrivacy"
      />
      <TripleFeature
        firstFeatureIcon={data.trusted.childImageSharp.fluid}
        secondFeatureIcon={data.expiredID.childImageSharp.fluid}
        thirdFeatureIcon={data.challengeQuestions.childImageSharp.fluid}
        firstHeadingText="Trusted Technology"
        secondHeadingText="Expired ID Flags"
        thirdHeadingText="Challenge Questions"
        firstDescriptionText="Patronscan's fake ID detection was developed using over 110 million scans and 15 years of innovation."
        secondDescriptionText="Receive alerts for expired IDs and identify minors using lost or stolen IDs to buy vapour products."
        thirdDescriptionText="Ask a set of challenge questions if you suspect an ID doesn't belong to them. Verify answers with the scanner."
      />
      <WhyPatronscan />
      <div className="vape-connect-spacing">
        <ConnectWithUs
          buttonId="VapeConnect"
          promptText="Request pricing information or book a live demo with one of our representatives and learn how Patronscan is protecting responsible vaping."
        />
      </div>
    </div>
  );
};

export default RetailVapeIndustry;
