import React, { useLayoutEffect, useState, FC, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { MarqueeLogo, State, Logo } from '../../models/logo';
import { BarClubLogos, VapeLogos, IndustryLogos } from './../../data/logos';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './logo-banner.css';
import { min } from 'date-fns';

export enum IndustryType {
  BarAndNightclub = 'BarAndNightclub',
  AutoDealership = 'AutoDealership',
  RetailVape = 'RetailVape',
  FakeIDScanner = 'FakeIdScanner',
}

interface LogoBannerProps {
  industry: IndustryType;
}

interface LogoDiffs {
  key: string;
  distance: number;
}

const LogoBanner: FC<LogoBannerProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      boxcar: file(relativePath: { eq: "logos/entertainment/boxcar.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      roxy: file(relativePath: { eq: "logos/entertainment/theroxy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mangos: file(relativePath: { eq: "logos/entertainment/mangos.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      twelveWest: file(relativePath: { eq: "logos/entertainment/twelveWest.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cowboys: file(relativePath: { eq: "logos/entertainment/cowboys.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      whiskeyRose: file(relativePath: { eq: "logos/entertainment/whiskeyRose.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      commonwealth: file(relativePath: { eq: "logos/entertainment/commonwealth.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      goldSpike: file(relativePath: { eq: "logos/entertainment/goldSpike.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ov: file(relativePath: { eq: "logos/entertainment/ov.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cabo: file(relativePath: { eq: "logos/entertainment/cabo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      elDorado: file(relativePath: { eq: "logos/entertainment/elDorado.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      liquid: file(relativePath: { eq: "logos/entertainment/liquid.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lucid: file(relativePath: { eq: "logos/entertainment/lucid.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      plainridge: file(relativePath: { eq: "logos/entertainment/plainridge.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      temple: file(relativePath: { eq: "logos/entertainment/temple.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cactus: file(relativePath: { eq: "logos/entertainment/cactus-club-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      donnelly: file(relativePath: { eq: "logos/entertainment/donnelly.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      altvape: file(relativePath: { eq: "logos/vape/altvape.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dawwgs: file(relativePath: { eq: "logos/vape/daawgs-vape.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      evape: file(relativePath: { eq: "logos/vape/e-vape.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      qcvapes: file(relativePath: { eq: "logos/vape/QCV.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  let closestLogosArray: LogoDiffs[] = [];
  const maxDistance = 300;

  const [visitorInfo, setVisitorInfo] = useState(null);
  const [localLogo1, setLocalLogo1] = useState(data.temple.childImageSharp.fluid);
  const [localLogo2, setLocalLogo2] = useState(data.boxcar.childImageSharp.fluid);
  const [localLogo3, setLocalLogo3] = useState(data.cowboys.childImageSharp.fluid);
  const [marqueeLogo, setMarqueeLogo] = useState(data.cactus.childImageSharp.fluid);
  const [industryLogos, setIndustryLogos] = useState(BarClubLogos);

  useLayoutEffect(() => {
    setVisitorInfo(JSON.parse(localStorage.getItem('vid')));
    setIndustryLogos(selectIndustryArray());
  }, []);

  useEffect(() => {
    if (visitorInfo) {
      getClosestLogos();
      setLocalLogo1(data[getLocalLogo(0)].childImageSharp.fluid);
      setLocalLogo2(data[getLocalLogo(1)].childImageSharp.fluid);
      setLocalLogo3(data[getLocalLogo(2)].childImageSharp.fluid);
      setMarqueeLogo(data[getMarqueeLogo()].childImageSharp.fluid);
    }
  }, [visitorInfo, closestLogosArray]);

  const selectIndustryArray = () => {
    let industryLogos: IndustryLogos;

    switch (props.industry) {
      case IndustryType.RetailVape:
        industryLogos = VapeLogos;
        break;
      case IndustryType.BarAndNightclub:
      default:
        industryLogos = BarClubLogos;
        break;
    }
    return industryLogos;
  };

  const getClosestLogos = () => {
    const industryLogos: IndustryLogos = selectIndustryArray();
    closestLogos(visitorInfo.lat, visitorInfo.long, industryLogos.logoSet);
  };

  const Deg2Rad = (deg) => {
    return (deg * Math.PI) / 180;
  };

  const PythagorasEquirectangular = (lat1, lon1, lat2, lon2) => {
    lat1 = Deg2Rad(lat1);
    lat2 = Deg2Rad(lat2);
    lon1 = Deg2Rad(lon1);
    lon2 = Deg2Rad(lon2);
    let R = 6371; // km
    let x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    let y = lat2 - lat1;
    let d = Math.sqrt(x * x + y * y) * R;
    return d;
  };

  const closestLogos = (latitude, longitude, industryLogos) => {
    let logosDistanceArray: LogoDiffs[] = [];

    for (let index = 0; index < industryLogos.length; index++) {
      const logoDistance: LogoDiffs = {
        key: industryLogos[index].key,
        distance: PythagorasEquirectangular(
          latitude,
          longitude,
          industryLogos[index].lat,
          industryLogos[index].long,
        ),
      };
      logosDistanceArray.push(logoDistance);
    }
    closestLogosArray = logosDistanceArray.sort((a, b) => {
      return a.distance - b.distance;
    });
  };

  const getLocalLogo = (logoIndex: number) => {
    if (visitorInfo.lat && visitorInfo.long && closestLogosArray[0].distance < maxDistance) {
      if (logoIndex === 0) {
        return closestLogosArray[0].key;
      }
      if (logoIndex === 1) {
        return closestLogosArray[1].key;
      }
      if (logoIndex === 2) {
        return closestLogosArray[2].key;
      }
    } else {
      if (logoIndex === 0) {
        return 'temple';
      }
      if (logoIndex === 1) {
        return 'boxcar';
      }
      if (logoIndex === 2) {
        return 'cowboys';
      }
    }
  };

  const getRandomInt = (min, max) => {
    const rand = min + Math.random() * (max - min);
    const randInt = Math.round(rand);
    return randInt;
  };

  const getMarqueeLogo = () => {
    const marqueeLogos: MarqueeLogo[] = [];
    const logos = industryLogos.marqueeLogoSet;

    if (logos && visitorInfo.state) {
      for (const logo of logos) {
        if (logo.state === visitorInfo.state) {
          marqueeLogos.push(logo);
        }
      }
    }
    if (marqueeLogos.length > 1) {
      const key = getRandomInt(0, marqueeLogos.length - 1);
      return marqueeLogos[key].key;
    } else {
      if (props.industry === IndustryType.RetailVape) {
        return 'altvape';
      } else {
        return 'donnelly';
      }
    }
  };

  return (
    <div className="logo-container">
      <div className="logo-mini-container">
        <Img className="logo" fluid={localLogo1} />
        <Img className="logo" fluid={localLogo2} />
      </div>
      <div className="logo-mini-container">
        <Img className="logo" fluid={marqueeLogo} />
        <Img className="logo" fluid={localLogo3} />
      </div>
    </div>
  );
};
export default LogoBanner;
