import React, { FC } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import { gaButtonEvent } from '../../services/visitor-info';
import './triple-feature.css';

interface TripleFeatureProps {
  firstFeatureIcon: any;
  secondFeatureIcon: any;
  thirdFeatureIcon: any;
  firstHeadingText: string;
  secondHeadingText: string;
  thirdHeadingText: string;
  firstDescriptionText: string;
  secondDescriptionText: string;
  thirdDescriptionText: string;
  firstCTAtext?: string;
  secondCTAtext?: string;
  thirdCTAtext?: string;
  firstCTALink?: string;
  secondCTALink?: string;
  thirdCTALink?: string;
}

const TripleFeature: FC<TripleFeatureProps> = (props) => {
  return (
    <div className="triple-feature">
      <div className="triple-feature-container">
        <div className="triple-feature-box">
          <div className="triple-featureicon-box">
            <Img className="triple-feature-icon" fluid={props.firstFeatureIcon} />
          </div>
          <p className="font-heading text-black triple-feature-title">{props.firstHeadingText}</p>
          <p className="text-base triple-feature-body">{props.firstDescriptionText}</p>
          {props.firstCTALink && (
            <div className="flex justify-center">
              <AniLink
                to="/"
                className="primary-cta-button"
                onClick={() => {
                  gaButtonEvent(props.firstCTAtext, `TripleFeature:${props.firstHeadingText}`);
                }}
                fadeto={props.firstCTALink}
              >
                {props.firstCTAtext}
              </AniLink>
            </div>
          )}
        </div>
        <div className="triple-feature-box">
          <div className="triple-featureicon-box">
            <Img className="triple-feature-icon" fluid={props.secondFeatureIcon} />
          </div>
          <p className="font-heading text-black triple-feature-title">{props.secondHeadingText}</p>
          <p className="text-base triple-feature-body">{props.secondDescriptionText}</p>
          {props.secondCTALink && (
            <div className="flex justify-center">
              <AniLink
                to="/"
                className="primary-cta-button"
                onClick={() => {
                  gaButtonEvent(props.secondCTAtext, `TripleFeature:${props.secondHeadingText}`);
                }}
                fadeto={props.secondCTALink}
              >
                {props.secondCTAtext}
              </AniLink>
            </div>
          )}
        </div>
        <div className="triple-feature-box">
          <div className="triple-featureicon-box">
            <Img className="triple-feature-icon" fluid={props.thirdFeatureIcon} />
          </div>
          <p className="font-heading text-black triple-feature-title">{props.thirdHeadingText}</p>
          <p className="text-base triple-feature-body">{props.thirdDescriptionText}</p>
          {props.thirdCTALink && (
            <div className="flex justify-center">
              <AniLink
                onClick={() => {
                  gaButtonEvent(props.thirdCTAtext, `TripleFeature:${props.thirdHeadingText}`);
                }}
                to="/"
                className="primary-cta-button"
                fadeto={props.thirdCTALink}
              >
                {props.thirdCTAtext}
              </AniLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TripleFeature;
