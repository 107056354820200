import { Logo, State, MarqueeLogo } from '../models/logo';

export interface IndustryLogos {
  logoSet: Logo[];
  marqueeLogoSet: MarqueeLogo[];
}

const BarClubLogosArray: Logo[] = [
  { key: 'boxcar', locationID: 'Vancouver', lat: 49.27, long: -123.1, active: true },
  { key: 'roxy', locationID: 'Vancouver', lat: 49.279665, long: -123.121823, active: true },
  { key: 'mangos', locationID: 'Vancouver', lat: 49.278116, long: -123.126359, active: true },
  { key: 'twelveWest', locationID: 'Vancouver', lat: 49.277029, long: -123.12667, active: true },
  { key: 'cowboys', locationID: 'Calgary', lat: 51.04, long: -114.05, active: true },
  { key: 'whiskeyRose', locationID: 'Calgary', lat: 51.038087, long: -114.084967, active: true },
  { key: 'commonwealth', locationID: 'Calgary', lat: 51.043546, long: -114.078298, active: true },
  { key: 'goldSpike', locationID: 'LasVegas', lat: 36.170893, long: -115.14, active: true },
  { key: 'ov', locationID: 'Winnipeg', lat: 49.87, long: -97.145714, active: true },
  { key: 'cabo', locationID: 'SanDiego', lat: 32.797854, long: -117.250772, active: true },
  { key: 'cabo', locationID: 'LA', lat: 34.101723, long: -118.335551, active: true },
  { key: 'elDorado', locationID: 'Reno', lat: 39.528789, long: -119.814354, active: true },
  { key: 'liquid', locationID: 'Madison', lat: 43.073547, long: -89.396926, active: true },
  { key: 'lucid', locationID: 'Milwaukee', lat: 43.039502, long: -87.906912, active: false },
  { key: 'plainridge', locationID: 'Plainville', lat: 42.034402, long: 71.305633, active: true },
  { key: 'temple', locationID: 'SanFrancisco', lat: 37.787972, long: -122.397215, active: false },
];
const BarClubMarqueeLogosArray: MarqueeLogo[] = [
  { key: 'cactus', state: State.BC, active: true },
  { key: 'donnelly', state: State.BC, active: true },
];

export const BarClubLogos: IndustryLogos = {
  logoSet: BarClubLogosArray,
  marqueeLogoSet: BarClubMarqueeLogosArray,
};

const VapeLogosArray: Logo[] = [
  { key: 'dawwgs', locationID: 'Edmonton', lat: 53.25735, long: -113.55822, active: true },
  { key: 'qcvapes', locationID: 'Regina', lat: 50.4507, long: -104.60305, active: true },
  { key: 'evape', locationID: 'Calgary', lat: 45.01907, long: -75.65024, active: true },
];

const VapeMarqueeLogosArray: MarqueeLogo[] = [{ key: 'altvape', state: State.AB, active: true }];

export const VapeLogos: IndustryLogos = {
  logoSet: VapeLogosArray,
  marqueeLogoSet: VapeMarqueeLogosArray,
};
